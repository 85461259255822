<template>
  <div class="generate-codes">
    <div class="container bg-white py-5">
      <div class="row mb-3">
        <div class="col">
          <form name="form" class="form form-horizontal" @submit.prevent="createCodes">
            <fieldset :disabled="creating">
              <div class="row">
                <div class="col-6">
                  <strong>Generate new codes</strong>
                  <div class="input-group">
                    <input type="number" name="numberOfCodes" v-model="numberOfCodes" class="form-control" placeholder="Number of codes">

                    <div class="input-group-append">
                      <button type="submit" class="btn btn-success">Create</button>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                </div>
              </div>
            </fieldset>
          </form>
        </div>
        <div class="col-auto">
          <strong>Import codes (CSV file)</strong><br>
          <input type="file" @change="processFile" ref="csvImportFile" accept="text/csv">
        </div>
        <div class="col-auto text-right">
          <button type="button" class="btn btn-secondary" @click="downloadCsv">Download CSV file</button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <strong>Number of codes:</strong> {{ codes.length }}
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Code</th>
                <th>Used?</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="codes.length == 0">
                <td colspan="3" class="text-center">No codes</td>
              </tr>
              <tr v-for="code in codes" :key="code.id">
                <td>{{ code.id }}</td>
                <td>{{ translator[code.used.toString()] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <modal open-event="openCSVImportModal" close-event="closeCSVImportModal">
      <div slot="modal">
        <fieldset :disabled="creating">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Import codes</h5>
                <button type="button" class="close" @click="eventHub.$emit('closeCSVImportModal')">&times;</button>
              </div>
              <div class="modal-body">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-nowrap" :class="{ 'bg-success text-white': index == codeIndex }" v-for="(column, index) in csvContentFirstRow" :key="`th${index}`" @click="setCodeIndex(index)">{{column}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in csvContentOtherRows" :key="`tr${index}`">
                        <td class="text-nowrap" :class="{ 'bg-success text-white': index == codeIndex }" v-for="(column, index) in row" :key="`td${index}`">{{column}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <strong>Click on the header column that contains the codes.</strong>

                <button type="button" class="btn btn-primary" @click="beginImport">Start import</button>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </modal>
  </div>
</template>

<script>
  import { firebase } from "@/initializers/firebase"
  import { range, sampleSize, slice } from "lodash"

  import Modal from "../components/modal.vue"

  export default {
    name: "home",
    beforeRouteEnter(to, from, next) {
      if(to.query.showme) {
        next()
      }
    },
    components: { Modal },
    data() {
      return {
        eventHub: window.eventHub,
        loading: true,
        creating: false,
        codes: [],
        numberOfCodes: null,
        translator: {
          "true": "Yes",
          "false": "No"
        },
        csvContent: [],
        codeIndex: null
      }
    },
    computed: {
      csvContentFirstRow() {
        return this.csvContent[0]
      },
      csvContentOtherRows() {
        return slice(this.csvContent, 1)
      }
    },
    methods: {
      setCodeIndex(index) {
        this.codeIndex = index
      },
      processFile() {
        this.csvContent = []

        if(this.$refs.csvImportFile.files.length == 0) { return }

        let file = this.$refs.csvImportFile.files[0]
        let reader = new FileReader()
        reader.readAsText(file)

        reader.onload = (event) => {
          let csv = event.target.result
          let rows = csv.split("\n")

          rows.forEach((row) => {
            let columns = row.split(/[,;]/g)

            this.csvContent.push(columns)
          }) 
        }

        window.eventHub.$emit("openCSVImportModal")
      },
      async beginImport() {
        this.creating = true

        let batch = firebase.firestore().batch()
        let count = 1

        for(const [index, row] of this.csvContentOtherRows.entries()) {
          console.log(`Importing row ${index}`)
          console.log(`Count ${count}`)
          let codeString = row[this.codeIndex].trim()
          let codeRef = await firebase.firestore().collection(`codes`).doc(codeString)
          let code = await codeRef.get()
          
          if(code.exists) {
            console.log(`Code already imported... Skipping`)
          } else {
            if(count == 500) {
              await batch.commit()
              batch = firebase.firestore().batch()
              count = 1
            }

            batch.set(codeRef, { currentUser: "", used: false })
            count = count + 1
          }
        }

        await batch.commit()

        this.$refs.csvImportFile.value = ""
        window.eventHub.$emit("closeCSVImportModal")
        this.csvContent = []
        this.creating = false
        this.codeIndex = null
      },
      downloadCsv() {
        let rows = [["Code"]]
        this.codes.forEach((code) => rows.push([code.id, ""]))

        let csvContent = "data:text/csv;charset=utf-8," + rows.map(row => row.join(",")).join("\n");

        let encodedUri = encodeURI(csvContent)
        let link = document.createElement("a")
        link.setAttribute("href", encodedUri)
        link.setAttribute("download", "dt-live-tickets.csv")
        link.click()
      },
      async generateCode() {
        return new Promise((resolve) => {
          (async () => {
            let characters = [
              "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
              "a", "b", "c", "d", "e", "f", "g", "h", "j", "k", "m", "n", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
              0, 2, 3, 4, 5, 6, 7, 8, 9
            ]

            let codeString = sampleSize(characters, 8).join("")

            let codeRef = await firebase.firestore().collection(`codes`).doc(codeString)
            let code = await codeRef.get()

            if(code.exists) {
              await this.generateCode()
            } else {
              resolve(codeString)
            }
          })()
        })
      },
      async createCodes() {
        this.creating = true
        let batch = firebase.firestore().batch()
        let codesRange = range(Number(this.numberOfCodes))

        for(let index of codesRange) {
          let code = await this.generateCode(index)
          let codeRef = await firebase.firestore().collection(`codes`).doc(code)

          batch.set(codeRef, {
            used: false,
            currentUser: ""
          })
        }

        await batch.commit()

        this.creating = false
      }
    },
    async mounted() {
      await this.$bind("codes", firebase.firestore().collection(`codes`))
      this.loading = false
    }
  }
</script>

<style lang="scss" scoped>
  .modal-content {
    max-height: calc(100vh - 150px);
  }

  .modal-body {
    overflow: scroll;
  }
</style>